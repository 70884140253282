import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

import GetItemInfo from '../GetItemInfo';
import './character.css';

class OthersShibanban extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
			<main id="banban-lp" className={this.state.cognitoid ? 'others chara-lp members' : 'others chara-lp'}>
			<Container fluid={true} className="bg_ye lp_catelist">
			<div className="pt-5 pb-2">
			<dl><dt><picture>
		<source media="(max-width: 840px)" srcSet="/img/shibanban/ttl_list_sp.png" />
			 <Image src="/img/shibanban/ttl_list_pc.png" alt="カテゴリーリスト" />
		 </picture></dt><dd>
			<ul><li><a href="/category/キャラクターから探す-しばんばん-シール/">シール</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-付箋/">付箋</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-レター/">レター</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-ボールペン/">ボールペン</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-ダイアリー/">ダイアリー</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-文具小物/">文具小物</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-ペンケース・ポーチ/">ペンケース</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-ぬいぐるみ/">ぬいぐるみ</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-雑貨/">雑貨</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-布製品/">布製品</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-化粧品/">化粧品</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-ランチ/">ランチ</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-その他紙製品/">その他紙製品</a></li>
			<li><a href="/category/キャラクターから探す-しばんばん-シャープ/">限定商品</a></li>
			</ul></dd></dl>
			</div>
			</Container>
<Container>

	 <Row>
		 	  <div className="top-img">
	   <h2>
		 		<Image src="/img/shibanban/lp_banban_head.jpg" alt="特集ページ" fluid />
	 	</h2>
	 </div>
		 </Row>
		 <Row><dl className="illustrator"><dt><Image src="/img/shibanban/ogoshi.png" alt="イラストレーターの紹介 オオゴシヤステル" fluid /></dt>
		 <dd>大阪府出身。<br />クスッと笑えるゆるめの雰囲気やキモカワ系のキャラクターで見る人の心をつかむ。<br />迷走するといつの間にか髪の毛が爆発ボンバーになる。<br />
		 <span className="sns-link"><a href="https://twitter.com/ogoshi_yasuteru"> <i className="fab fa-twitter"></i></a><a href="https://www.instagram.com/ogoshi_yasuteru/"> <i className="fab fa-instagram"></i></a><a href="https://twitter.com/shibanban_mw"> <i className="fab fa-twitter"></i></a></span></dd></dl></Row>
</Container>
<Container>
<div className="pu-item">
	 <h3 className="nyansuke_ttl pu-item_ttl">
	 <Image src="/img/shibanban/ttl_puitem_gr.png" alt="注目のアイテムをご紹介！" />
	 </h3>

	 <h4 className="nyansuke_sttl pu-item_sttl">
	 <Image src="/img/nyansuke/subttl_01.png" alt="オンラインストア限定アイテム" />
	 </h4>
		<Row>
			<Col xs={12} sm={4} ><a href="/others/mobilebattery/" className="opa06"><Image src="/img/shibanban/recommend_banban01.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/others/glasscase/" className="opa06"><Image src="/img/shibanban/recommend_banban02.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/iP_032ring/" className="opa06"><Image src="/img/shibanban/recommend_banban03.jpg" alt="注目のアイテム" fluid /></a></Col>
	 </Row>
	 <ul className="text-right puitem-list">
	 <li><a href="/others/mobilebattery/">◆受注生産◆ワイヤレス充電器［メーカー発送］ ￥4,378<span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/others/glasscase/">◆受注生産◆スマホケース［メーカー発送］ ￥<GetItemInfo code="iP_055gs_sq_X" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/iP_032ring/">ダイカットスマホリング［メーカー発送］ ￥<GetItemInfo code="iP_032ring" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li></ul>

	 <h4 className="nyansuke_sttl pu-item_sttl">
	 <Image src="/img/shibanban/subttl_02.png" alt="いつでもしばんばんと一緒" />
	 </h4>
		<Row>
			<Col xs={12} sm={4} ><a href="/item/61717/" className="opa06"><Image src="/img/shibanban/recommend_banban10.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/61711/" className="opa06"><Image src="/img/shibanban/recommend_banban11.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/95078/" className="opa06"><Image src="/img/shibanban/recommend_banban06.jpg" alt="注目のアイテム" fluid /></a></Col>
	 </Row>
	 <ul className="text-right puitem-list">
	 <li><a href="/item/61717/"><GetItemInfo code="61717" setkey="name" /> ￥<GetItemInfo code="61717" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/61711/"><GetItemInfo code="61711" setkey="name" /> ￥<GetItemInfo code="61711" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/95078/"><GetItemInfo code="95078" setkey="name" /> ￥<GetItemInfo code="95078" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li></ul>
</div>
	 </Container>
	 <h3 className="nyansuke_ttl pu-item_ttl mt-5 pt-3">
	 <Image src="/img/shibanban/ttl_pucate_gr.png" alt="注目のカテゴリーをご紹介！" />
	 </h3>
	 <Container className="my-5">
	 
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/shibanban/cate_gentei_gr_sp.png" />
		<Image className="my-5" src="/img/shibanban/cate_gentei_gr.png" alt="オンラインストア限定" fluid />
	</picture>
	<div className="pucate-item">
	<Row>
		<Col xs={6} sm={3} ><a href="/item/61684/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61684_main.jpg" alt="" fluid /></a>
		<p><a href="/item/61684/"><GetItemInfo code="61684" setkey="name" /></a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/61896/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61896_main.jpg" alt="" fluid /></a>
		<p><a href="/item/61896/"><GetItemInfo code="61896" setkey="name" /></a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/61890/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61890_main.jpg" alt="" fluid /></a>
		<p><a href="/item/61890/"><GetItemInfo code="61890" setkey="name" /></a></p></Col>
		<Col xs={6} sm={3} ><a href="/item/61429/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61429_main.jpg" alt="" fluid /></a>
		<p><a href="/item/61429/"><GetItemInfo code="61429" setkey="name" /></a></p></Col>
 </Row>
 </div>
 <div className="text-right"><a href="/category/キャラクターから探す-しばんばん-限定商品/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
	 </Container>
	 <Container fluid={true} className="bg_gr py-5 my-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_nuigurumi_gr_sp.png" />
 <Image className="my-5" src="/img/shibanban/cate_nuigurumi_gr.png" alt="ぬいぐるみ" fluid />
</picture>
<div className="pucate-item">
<Row>
 <Col xs={12} sm={3} ><a href="/item/95352/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95352_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95352/"><GetItemInfo code="95352" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/95353/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95353_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95353/"><GetItemInfo code="95353" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/94192/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94192_main.jpg" alt="" fluid /></a>
 <p><a href="/item/94192/"><GetItemInfo code="94192" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} >
  {/*<Row className="mb-5">
<Col xs={6} sm={6} ><a href="/item/94653/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94653_main.jpg" alt="" fluid /></a></Col>
 <Col xs={6} sm={6} ><a href="/item/94655/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94655_main.jpg" alt="" fluid /></a></Col></Row>
 <Row><Col xs={6} sm={6} ><a href="/item/94538/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94538_main.jpg" alt="" fluid /></a></Col>
 <Col xs={6} sm={6} ><a href="/item/94537/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94537_main.jpg" alt="" fluid /></a></Col></Row>*/}
<div className="nui-slist">
<a href="/item/61717/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61717_main.jpg" alt="" fluid /></a>
<a href="/item/94186/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94186_main.jpg" alt="" fluid /></a>
<p><a href="/search/しばんばん%E3%80%80ぬいぐるみマスコット/">ぬいぐるみマスコット</a></p></div>
<div className="nui-slist">
<a href="/item/94547/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94547_main.jpg" alt="" fluid /></a>
<a href="/item/94548/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94548_main.jpg" alt="" fluid /></a>
<p><a href="/search/しばんばん%E3%80%80モチモチマスコット/">モチモチマスコット</a></p></div>
</Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-ぬいぐるみ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>
	 <Container className="my-5">
	 
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/shibanban/cate_collabo_gr_sp.png" />
		<Image className="my-5" src="/img/shibanban/cate_collabo_gr.png" alt="ごろにゃん＆しばんばんコラボ" fluid />
	</picture>
	<div className="pucate-item">
	<Row>
		<Col xs={12} sm={4} ><a href="/item/57391/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57391_main.jpg" alt="" fluid /></a>
		<p><a href="/item/57391/"><GetItemInfo code="57391" setkey="name" /></a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/94268/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94268_main.jpg" alt="" fluid /></a>
		<p><a href="/item/94268/"><GetItemInfo code="94268" setkey="name" /></a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/57357/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57357_main.jpg" alt="" fluid /></a>
		<p><a href="/item/57357/"><GetItemInfo code="57357" setkey="name" /></a></p></Col>
 </Row>
 </div>
 <div className="text-right"><a href="/category/キャラクターから探す-ごろにゃん＆しばんばん%E3%80%80コラボ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
	 </Container>

<Container fluid={true} className="bg_gr py-5 my-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_seal_gr_sp.png" />
 <Image className="my-5" src="/img/shibanban/cate_seal_gr.png" alt="シール" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/80815/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/80815_main.jpg" alt="" fluid /></a>
 <p><a href="/item/80815/"><GetItemInfo code="80815" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/80720/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/80720_main.jpg" alt="" fluid /></a>
 <p><a href="/item/80720/"><GetItemInfo code="80720" setkey="name" /></a></p></Col>
  <Col xs={6} sm={3} ><a href="/item/81582/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81582_main.jpg" alt="" fluid /></a>
 <p><a href="/item/81582/"><GetItemInfo code="81582" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/80936/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/80936_main.jpg" alt="" fluid /></a>
 <p><a href="/item/79112/"><GetItemInfo code="80936" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-シール/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_husen_gr_sp.png" />
 <Image className="my-5" src="/img/shibanban/cate_husen_gr.png" alt="ふせん" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/54149/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/54149_main.jpg" alt="" fluid /></a>
<p><a href="/item/54149/"><GetItemInfo code="54149" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57695/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57695_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57695/"><GetItemInfo code="57695" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/56890/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/56890_main.jpg" alt="" fluid /></a>
 <p><a href="/item/56890/"><GetItemInfo code="56890" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57792/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57792_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57792/"><GetItemInfo code="57792" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-付箋/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>

{/*<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_tape_gr_sp.png" />
 <Image className="my-5" src="/img/shibanban/cate_tape_gr.png" alt="テープ" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/80571/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/80571_main.jpg" alt="" fluid /></a>
<p><a href="/item/80571/"><GetItemInfo code="80571" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/93933/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/93933_main.jpg" alt="" fluid /></a>
 <p><a href="/item/93933/"><GetItemInfo code="93933" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/79645/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/79645_main.jpg" alt="" fluid /></a>
 <p><a href="/item/79645/"><GetItemInfo code="79645" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/79644/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/79644_main.jpg" alt="" fluid /></a>
 <p><a href="/item/79644/"><GetItemInfo code="79644" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-テープ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
*/}

{/* 
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_cosme_gr_sp.png" />
 <Image className="mt-5" src="/img/shibanban/cate_cosme_gr.png" alt="化粧品" fluid />
</picture>
<h4 className="">
 <Image className="mb-4 ttl_furupuru" src="/img/nyansuke/ttl_furupuru.png" alt="FURUPURU COSME" />
 </h4>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/search/しばんばん%E3%80%80フルプルクリーム" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/93679_main.jpg" alt="" fluid /></a>
<p className="s-cate"><a href="/search/しばんばん%E3%80%80フルプルクリーム/">クリーム  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
 <Col xs={6} sm={3} ><a href="/search/しばんばん%E3%80%80ハンドクリーム/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/93991_main.jpg" alt="" fluid /></a>
<p className="s-cate"><a href="/search/しばんばん%E3%80%80ハンドクリーム/">ハンドクリーム  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
 <Col xs={6} sm={3} ><a href="/search/しばんばん%E3%80%80フェイスマスク/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94582_main.jpg" alt="" fluid /></a>
<p className="s-cate"><a href="/search/しばんばん%E3%80%80フェイスマスク/">フェイスマスク  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
 <Col xs={6} sm={3} ><a href="/search/しばんばん%E3%80%80バスソルト/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/CA190406_main.jpg" alt="" fluid /></a>
 <p className="s-cate"><a href="/search/しばんばん%E3%80%80バスソルト/">バスソルト  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-化粧品/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
*/}
<Container fluid={true} className="bg_gr py-5 mt-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/shibanban/cate_zakka_gr_sp.png" />
 <Image className="my-5" src="/img/shibanban/cate_zakka_gr.png" alt="雑貨" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/61317/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61317_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61317/"><GetItemInfo code="61317" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/61551/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61551_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61551/"><GetItemInfo code="61551" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/95258/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95258_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95258/"><GetItemInfo code="95258" setkey="name" /></a></p></Col>
  <Col xs={6} sm={3} ><a href="/item/57626/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57626_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57626/"><GetItemInfo code="57626" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-しばんばん-雑貨/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>
<Container className="mb-3"><Row>
<Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="/category/キャラクターから探す-しばんばん/" className="btn_gr w-100 btn btn-smp btn-lg py-3 my-5">全商品一覧はこちら <i className="fa fa-arrow-right"></i></a></Col>
</Row></Container>
<Container className="">
<a href="../30th/"><picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_30th_sp.jpg" />
<Image className="mb-3" src="/img/top/bnr_30th.jpg" alt="30周年特設ページ" fluid />
</picture></a>
<Row>
 <Col xs={12} sm={6} ><a href="../nyansuke/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-nyansuke_sp.jpg" alt="ごろごろにゃんすけ" fluid /></a></Col>
 <Col xs={12} sm={6} ><a href="../piyokomame/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-piyoko_sp.jpg" alt="ぴよこ豆" fluid /></a></Col>
  {/*<Col xs={12} sm={6} ><Image className="mb-3" src="/img/202002/bnr_lp-piyoko_sp02.jpg" alt="ぴよこ豆" fluid /></Col>*/}
 </Row>
 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る <i className="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersShibanban);
